import {BuyNowLiteConstants} from '../../../helpers/Constants';


export function generateHeader() {
    let header = new Headers();
    header.append(BuyNowLiteConstants.accessControlAllowOrigin, '*');
    header.append(BuyNowLiteConstants.xFunctionKey, process.env.BUY_NOW_LITE_FUNCTION_KEY);
    header.append(BuyNowLiteConstants.passKey, process.env.BUY_NOW_LITE_PASS_KEY);
    header.append(BuyNowLiteConstants.ocpApimSubscriptionKey, process.env.BUY_NOW_LITE_SUBSCRIPTION_KEY);

    return header;
}

export const getBuyNowLiteRetailerList = (sku, onSuccess = function(){}, onFailure = function(){}) => {
    const header = generateHeader();

    const requestOptions = {
        method: 'GET',
        headers: header,
    };

    let url = process.env.BUY_NOW_LITE_URL.replace('{sku}', sku);

    fetch(url, requestOptions)
        .then(response => response.json())
        .then(data => {
            if(data.constructor === Array) {
                onSuccess(data);
            } else {
                onFailure();
            }
        })
        .catch(error => {
            console.error(error);
            onFailure();
        });
}